import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {Container} from 'reactstrap'

const Header = ({ siteTitle }) => (
  <header
    style={{
      // background: `#FF0000`,
      // marginBottom: `1.45rem`,
    }}
    className='fixed-top'
  >
    <Container
      style={{
        margin: `0 auto`,
        padding: `1.0rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }} className='header-h1'>
        <Link
          to="/"
          style={{
            textDecoration: `none`,
            color: 'black'
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
